<template>
  <div>
    <Title title="项目阶段进度" />

    <div class="wrapper">
      <template v-if="phaseList.length > 0">
        <a-alert message="请及时更新进度，当进度100%时，需提交相关资料以供进度确认审核。" type="info" show-icon />

        <Padding />
      </template>

      <Progress
        v-for="(item, index) in phaseList"
        :key="index"
        :index="index"
        :phase="item"
        @setFileList="setFileList"
        @setVisible="setVisible"
        @changeProgress="onProgressChange"
      />

      <div class="center" style="margin-top: 24px" v-if="phaseList.length > 0">
        <a-button type="primary" :loading="saveLoading" :disabled="saveDisabled" @click="save">提交审批</a-button>
      </div>
      <div v-else>
        <a-empty />
      </div>
    </div>

    <Comment :visible="commentVisible" @setVisible="setCommentVisible" @done="done" />
  </div>
</template>

<script>
import Title from "../title";
import { updateProgress } from "@/api/project";
import Progress from "./progress";
import { mapMutations, mapState, mapActions } from "vuex";
import Comment from "./comment";
export default {
  components: {
    Title,
    Progress,
    Comment
  },

  data() {
    return {
      active: 0,

      commentVisible: false, // 审批意见弹窗

      saveLoading: false // 提交审批按钮 loading
    };
  },
  computed: {
    ...mapState("projectDetail", ["phaseList", "project"]),

    planReceiptList() {
      if (
        this.project.contract &&
        Array.isArray(this.project.contract.planReceiptList)
      ) {
        return this.project.contract.planReceiptList;
      } else {
        return [];
      }
    },

    subPackages() {
      if (Array.isArray(this.project.subPackages)) {
        return this.project.subPackages;
      } else {
        return [];
      }
    },

    saveDisabled() {
      // 合同审批还没过，不可以提交审批
      if (!this.project.contract) {
        return true;
      }
      if (this.project.contract.configureStatus !== "approved") {
        return true;
      }

      // 不考虑审批中和审批通过的阶段
      const filteredList = this.phaseList.filter(
        item =>
          item.phaseProgressStatus !== "reviewing" &&
          item.phaseProgressStatus !== "approved" &&
          item.progress === 100
      );

      // 当没有100%进度的阶段时禁用
      if (filteredList.length === 0) {
        return true;
      }

      // 当填写100%进度的阶段没有文件的时候禁用
      let noFile = false;
      filteredList.forEach(item => {
        if (!item.outputList) {
          noFile = true;
        }
        if (item.outputList && item.outputList.length === 0) {
          noFile = true;
        }
      });
      return noFile;
    }
  },

  methods: {
    ...mapActions("projectDetail", ["fetchProject", "fetchPhaseList"]),

    onProgressChange(payload) {
      const { index, value } = payload;

      const item = this.phaseList[index];
      item.progress = value;
      this.phaseList.splice(index, 1, item);
      this.setPhaseList([...this.phaseList]);
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
    },

    // 用户填完审批意见
    done({ comment, selectedRowKeys }) {
      const params = [
        ...this.phaseList
          .filter(
            item =>
              item.progress === 100 &&
              item.phaseProgressStatus !== "reviewing" &&
              item.phaseProgressStatus !== "approved"
          )
          .map(item => {
            return {
              id: item.id,
              progress: item.progress
            };
          })
      ];

      if (params.length > 0) {
        this.saveLoading = true;

        // 用户填了100%，并且这个阶段不在审批或审批通过

        updateProgress({
          comment,
          updateReq: params,
          receiptUpdateReq: this.planReceiptList.map(item => {
            let isFinished = 0;
            if (selectedRowKeys.find(key => key === item.id)) {
              isFinished = 1;
            }
            return {
              ...item,
              isFinished
            };
          })
        })
          .then(() => {
            this.fetchProject();
            this.fetchPhaseList();
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        this.$message.error("暂无需要发起审批的阶段！");
      }
    },

    save() {
      // const filteredList = this.phaseList.filter(
      //   item =>
      //     item.phaseProgressStatus == "rejected"
      // );
      // const that = this;
      // if(filteredList.length > 0) {
      //   this.$confirm({
      //     title: "当前项目进度审批流程已被驳回，是否前往待办任务列表处理？",
      //     onOk() {
      //       that.$router.push(`/approval/task`);
      //     },
      //   });
      // }else{
        this.commentVisible = true;
      // }
    },

    ...mapMutations("projectDetail", ["setPhaseList"]),

    setFileList({ phase, fileList }) {
      console.log("set file list father ", phase, fileList);
      const index = this.phaseList.findIndex(item => item.id === phase.id);
      if (index > -1) {
        this.phaseList.splice(index, 1, {
          ...phase,
          outputList: fileList
        });

        this.setPhaseList([...this.phaseList]);
      }
    },

    setVisible() {
      const { id, contract } = this.project;
      const { allocateCalcFormula, allocateCalcFactor } = contract || {};
      if (allocateCalcFormula === "2025") {
        this.$router.push(
          "/produce/progress/allocate2025?id=" +
            id +
            "&factor=" +
            allocateCalcFactor
        );
      }else if (allocateCalcFormula === "2024") {
        this.$router.push(
          "/produce/progress/allocate2024?id=" +
            id +
            "&factor=" +
            allocateCalcFactor
        );
      }else if (allocateCalcFormula === "2023") {
        this.$router.push(
          "/produce/progress/allocate2023?id=" +
            id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2022") {
        this.$router.push(
          "/produce/progress/allocate2022?id=" +
            id +
            "&factor=" +
            allocateCalcFactor
        );
      } else if (allocateCalcFormula === "2021") {
        this.$router.push("/produce/progress/allocate2021?id=" + id);
      } else {
        this.$router.push("/produce/progress/allocate?id=" + id);
      }
    }
  }
};
</script>


<style lang="less" scoped>
.wrapper {
  background-color: #fff;
  padding: 12px;

  .phase-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 8px;

    .content {
      grid-column-start: 1;
      grid-column-end: 3;
      padding: 12px;
      background-color: #f5f5f5;
    }

    .detail {
      grid-column-start: 3;
      grid-column-end: 6;
      height: 400px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .progress {
      color: #1890ff;
      font-size: 18px;
      font-weight: bold;
      margin-right: 2px;
    }
  }
}
</style>